import React, { useEffect } from 'react';
import {
  enrollEmailWithoutOTP, getFactorsEnrolled, getUserInfoDetails,
  incrementUIBypassCounter, verifyAndActivateFactorAPI,
  resendCode, sendPasscodeViaSMSOrCall, updateEmailInProfile, updatePhoneInProfile
} from '../resource/APIServices';
import PhoneInput from '../../src/json/PhoneInput'
import classes from "../../src/styles/signin.scss";
import 'bootstrap/dist/css/bootstrap.css';
import 'react-phone-input-2/lib/style.css'
import { globalFactorId, envConfig } from '../config';
import Phone from '../../src/json/phone'
import { useLocation, useHistory } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { fetchFeatureFlag } from '../Utility/helpers/functions';
import { RELEASE_1_0,RELEASE_1_1 } from '../Utility/constants';
import { getSpiningLoader } from '../Utility/helpers/functions';

const EnrollmentForm = ({ props }) => {
  console.log('EnrollmentForm Props Received--' + props)
  const location = useLocation();
  console.log('EnrollmentForm location Received--' + JSON.stringify(location))

  const [errorMessage, setErrorMessage] = React.useState("");
  const [alertMessage, setAlertMessage] = React.useState("");
  const [passCodeAlertMessage, setPassCodeAlertMessage] = React.useState("");
  const [extensionAlertMessage, setExtensionAlertMessage] = React.useState("");
  const [phoneNumberAlertMessage, setPhoneNumberAlertMessage] = React.useState("");
  const [passcodeCounter, setPasscodeCounter] = React.useState(0);

  const [userInfo, setUserInfo] = React.useState(null);
  const history = useHistory();
  const { oktaAuth, authState } = useOktaAuth();
  let oktaUserInfoObj;
  const [alertMsg, setAlertMsg] = React.useState(true);
  const [releaseFeatureFlag, setReleaseFeatureFlag] = React.useState(null);

  useEffect(() => {
    async function fetchUserInfoAndFactors() {
      let info = await getUserInfoDetails();
      setUserInfo(info);
    }
    fetchUserInfoAndFactors();
  }, [location]);

  useEffect(async () => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(null);
    } else {
        try {
          oktaUserInfoObj = await oktaAuth.getUser();
          console.log("oktaUserInfoObj ===> ", oktaUserInfoObj)
          if (oktaUserInfoObj.groups.includes('O-VantusHQ')){
            console.log("inside true");
            setAlertMsg(true);
          }
          else{
            console.log("inside false");
            setAlertMsg(false);
          }
        } catch (e) {
          console.error(e);
        }
      
    }
  }, [authState, oktaAuth, location])

  useEffect(() =>{
    if(passcodeCounter >= 10){
      setPasscodeCounter(0);
      history.push('/signout')
    }
  },[passcodeCounter]);

//   useEffect(() => {
//     if (userInfo && userInfo.data.groups && userInfo.data.groups.indexOf('O-VantusHQ') !== -1) {
//         document.title = "Vantus\u2122 HQ";
//     } else {
//         document.title = "Order Express"; 
//     }
// }, [userInfo]);

useEffect(() => {
  let whereItcameFrom_sessionStorage = sessionStorage.getItem("whereItCameFrom");
  if (whereItcameFrom_sessionStorage === "vantus") {
      document.title = "Vantus\u2122 HQ";
  }
},[]);
useEffect(() => {
  const fetchFlag = async () => {
    const releaseFlag = await fetchFeatureFlag();
    setReleaseFeatureFlag(releaseFlag);
  };

  fetchFlag();
}, []);



  const handleSubmitEmailWithoutOTP = async (e) => {
    const emailEntered = document.getElementById("enrollment-emailId").value;
    e.preventDefault();
    if (emailEntered === "") {
      setAlertMessage("Please enter email address.");
    } else {
      setErrorMessage("");
      setAlertMessage("");
      if (userInfo && userInfo.data && userInfo.data.email) {
        if (userInfo.data.email !== emailEntered) {
          console.log('Updating Email in Profile since User trying to change the email to enroll than whats in profile')
          const emailUpdateInProfileResponse = await updateEmailInProfile(emailEntered);
          console.log('Email Update in Profile Response ' + JSON.stringify(emailUpdateInProfileResponse))
        } else {
          console.log('User enrolling the same email id which is in profile');
        }
      }
      let enrollEmailResponse = await enrollEmailWithoutOTP(emailEntered);
      if (enrollEmailResponse.status === 200) {
        history.push({
          pathname: "/redirect"
        });
      } else {
        console.log("Enroll Email API Error" + JSON.stringify(enrollEmailResponse));
        history.push("/generic-error");
      }
    }
  };

  const verifyAndActivateFactorAPICall = selectedFactorType => async (e) => {
    e.preventDefault();
    if (document.getElementById('inputPasscode').value === '') {
      setPassCodeAlertMessage('Passcode is required.');
    } else {
      setAlertMessage("");
      let passcode = document.getElementById("inputPasscode").value
      let factorEnrollmentId;
      if (globalFactorId.enrollmentId && globalFactorId.enrollmentId !== null) {
        factorEnrollmentId = globalFactorId.enrollmentId
      } else {
        let factorDetails = await getFactorsEnrolled()
        if (factorDetails && factorDetails.data) {
          for (let eachFactor of factorDetails.data) {
            if (eachFactor.factorType === selectedFactorType && eachFactor.status === 'PENDING_ACTIVATION') {
              factorEnrollmentId = eachFactor.id;
            }
          }
        }
      }
      const activateFactorResposne = await verifyAndActivateFactorAPI(passcode, factorEnrollmentId)

      if (activateFactorResposne && activateFactorResposne.data && activateFactorResposne.data.status === "ACTIVE") {
        let dialcode = document.getElementById('dialCode').value
        const phoneNumberElement = document.getElementsByName("phoneNumber");
        let phonenumber = phoneNumberElement[0].value
        let formattedNumber = dialcode + phonenumber;
        formattedNumber = formattedNumber.replace(/[^\d]/g, "");
        if (selectedFactorType === 'call' && document.getElementById('extensionNumber') && document.getElementById('extensionNumber').value.length > 0) {
          formattedNumber = formattedNumber + 'x' + document.getElementById('extensionNumber').value
        }
        const updatePhoneInProfileRes = await updatePhoneInProfile(formattedNumber, selectedFactorType)
        console.log('Response Update Phone ' + JSON.stringify(updatePhoneInProfileRes))
        history.push({ pathname: "/enrolled", search: selectedFactorType, state: { factorEnrolled: selectedFactorType } });
      } else {
        console.log('Activate Factor Enrollment not Active ' + JSON.stringify(activateFactorResposne))
        setPassCodeAlertMessage('The passcode you entered is incorrect. Please try again or send a new passcode.')
        setPasscodeCounter(passcodeCounter+1);
      }
    }
  }

  const clearPasscodeAlert = (e) => {
    if(["e", "E", "+", "-", "."].includes(e.key)) {
      e.preventDefault();
    }
    setPassCodeAlertMessage("")
    setPhoneNumberAlertMessage("")
  }

  const handleBack = (e) => {
    e.preventDefault();
    history.push('/signout');
  }

  const handleBackSkipEnrollment = async (e) => {
    e.preventDefault();
    var userInfoo = await getUserInfoDetails();
    if (userInfoo && userInfoo.data && userInfoo.data.UIBypassCounter) {
      var counter = parseInt(userInfoo.data.UIBypassCounter);
      counter++;
      const isSuccess = await incrementUIBypassCounter(counter.toString());
    }
    history.push({ pathname: "/redirect", search: "skipEnrollment", state: { skipEnrollment: true } });
  }

  const sendPasscodeAPICall = factorType => async (e) => {
    e.preventDefault();
    const phoneNumberElement = document.getElementsByName("phoneNumber");
    if (phoneNumberElement.length > 0 && phoneNumberElement[0].value.replace(/[^\d\+]/g, "").length === 10) {
      setPhoneNumberAlertMessage("")
      document.getElementById('passcodeInputbox').className = "show"
      document.getElementById('passcodebutton').className = "hidesendpasscode"
      // document.getElementById('passcodehideshow').className = "hidesendpasscode"
      document.getElementById('beforResendPage').className = "hide"
      document.getElementById('inputBeforeBox').className = "hide"
      document.getElementById('labelBeforeBox').className = "hide"
      document.getElementById('afteResendPage').className = "show"
      if(document.getElementById('extention-after-resend-code')) {
        document.getElementById('extention-after-resend-code').className = "hide"
      }
      if(document.getElementById('passcodehideshownew')) {
        document.getElementById('passcodehideshownew').className = "hide"
      }

      if (document.getElementsByName('phoneNumber').length > 0) document.getElementsByName('phoneNumber')[0].setAttribute('readonly', 'readonly') //disable phonenumber
      if (document.getElementsByName('extensionNumber').length > 0) document.getElementsByName('extensionNumber')[0].setAttribute('readonly', 'readonly') //disable extensionNumber
      if (document.getElementsByClassName('selected-flag').length > 0) document.getElementsByClassName('selected-flag')[0].style.display = 'none'; //disable country select

      const resendPasscodeBtn = document.getElementById("resendPasscode")
      resendPasscodeBtn.disabled = true;
      setTimeout(() => {
        if (globalFactorId.enrollmentId !== null) {
          resendPasscodeBtn.disabled = false;
          resendPasscodeBtn.setAttribute('class', 'button button-secondary button-wide email-button')
          // document.getElementById("resendPasscode").className = "button"
        } else {
          console.error('Didnt get Enrollment Factor Id, so not enabling Resend Passcode Button')
        }
      }, 30000)
      let dialcode = document.getElementById('dialCode').value
      let phonenumber = phoneNumberElement[0].value
      let formattedNumber = dialcode + phonenumber
      let extensionNumber = ''
      if (document.getElementById('extensionNumber')) {
        extensionNumber = document.getElementById('extensionNumber').value
      }
      const sendPasscodeResponse = await sendPasscodeViaSMSOrCall(formattedNumber.replace(/[^\d\+]/g, ""), extensionNumber, factorType);
      console.log('Send Passcode API Response ' + JSON.stringify(sendPasscodeResponse))
      if (sendPasscodeResponse && sendPasscodeResponse.data && sendPasscodeResponse.data.id) {
        globalFactorId.enrollmentId = sendPasscodeResponse.data.id
      }
    } else {
      setPhoneNumberAlertMessage("Phone number is required.")
    }

  }

  const resendPassCode = factorType => async (e) => {
    e.preventDefault();
    const resendPasscodeBtn = document.getElementById("resendPasscode")
    resendPasscodeBtn.disabled = true;
    resendPasscodeBtn.setAttribute('class', 'button button-secondary button-wide email-button link-button-disabled')
    setTimeout(() => {
      resendPasscodeBtn.disabled = false;
      resendPasscodeBtn.setAttribute('class', 'button button-secondary button-wide email-button')
    }, 30000)
    let dialcode = document.getElementById('dialCode').value
    const phoneNumberElement = document.getElementsByName("phoneNumber");
    let phonenumber = phoneNumberElement[0].value
    let formattedNumber = dialcode + phonenumber
    const resendPasscodeResponse = await resendCode(formattedNumber.replace(/[^\d\+]/g, ""), globalFactorId.enrollmentId, factorType);
    console.log("Resend Passcode API Resposne ", resendPasscodeResponse)
  }

  const orderExpress = async (e) => {
    e.preventDefault();
    history.push('/redirect');
  }

  const emailValidator = (e) => {
    let enteredEmailAddr = "";
    if (e && e.target && e.target.value) enteredEmailAddr = e.target.value;
    var emailValidatorRegex = /\S+@\S+\.\S+/;
    let submitEmailBtn = document.getElementById('submitEmail');
    if (enteredEmailAddr && !emailValidatorRegex.test(enteredEmailAddr)) {
      submitEmailBtn.disabled = true;
      setAlertMessage(`Enter a valid email address. Please include "@" and "." (e.g. example@email.com).`);
    } else {
      submitEmailBtn.disabled = false;
      setAlertMessage(undefined);
    }
  }

  const extensionNumberValidator = (e) => {
    let enteredExtensionNumber = "";
    if (e && e.target && e.target.value) enteredExtensionNumber = e.target.value;
    var numAndCommaRegex = /^[0-9.,]+$/;
    let passcodeBtn = document.getElementById('passcodebutton');
    if (enteredExtensionNumber && !numAndCommaRegex.test(enteredExtensionNumber)) {
      passcodeBtn.disabled = true;
      setExtensionAlertMessage("Invalid Format: Extension number cannot include any letters or special characters.");
    } else {
      passcodeBtn.disabled = false;
      setExtensionAlertMessage(undefined);
    }
  }

  //email enrollment
  if (props === 'email-enrollment') {
    var emailId = ""
    if (userInfo && userInfo.data.email) {
      emailId = userInfo.data.email
    }
   
    return (
      <div style={{ classes }} data-testid="email-enrollment" className='forgot-username'>
        <form onSubmit={handleSubmitEmailWithoutOTP} data-se="o-form" slot="content" id="form_username_email" className="o-form forgot-username o-form-edit-mode">
          <div data-se="o-form-content" className="o-form-content o-form-theme clearfix">
            <h2 data-se="o-form-head" className="heading-inner-page d-block">Account recovery set up</h2>
            {(userInfo && userInfo.data.groups && userInfo.data.groups.indexOf('O-VantusHQ') !== -1)
                ?
             
              <div>
                <span className="d-none">
                {(document.title = "Vantus\u2122 HQ")}
                </span>
              <p className="sub-heading-inner-page mt-n1">For your security, {"Vantus\u2122HQ"} now requires two methods of account recovery. </p>
              <p className="sub-heading-inner-page mt-3">To begin, please confirm or update your email address.</p>
              
              {/*<hr id="forgot-password-title-hr" />*/}
              </div> :
              <div>
              <p className="sub-heading-inner-page mt-n1">For your security, Order Express now requires two methods of account recovery. </p>
              <p className="sub-heading-inner-page mt-3">To begin, please confirm or update your email address.</p>
             
              {/*<hr id="forgot-password-title-hr" />*/}
              </div>
            }
      
            <div className="o-form-fieldset-container" data-se="o-form-fieldset-container">
              <div data-se="o-form-fieldset" className="o-form-fieldset o-form-label-top mb-0">
                <div data-se="o-form-label" className="okta-form-label o-form-label">
                  <label id="forgot-username">Email address</label>
                </div>
                <div data-se="o-form-input-container" className="o-form-input o-form-has-errors">
                  <span data-se="o-form-input-username" className="o-form-input-name-username o-form-control okta-form-input-field input-fix o-form-has-errors">
                    <input type="text" placeholder="Enter email address" aria-invalid={alertMessage ? true : false} defaultValue={emailId} name="emailAddress" id="enrollment-emailId" onBlur={emailValidator} onFocus={emailValidator} />
                  </span>
                  {alertMessage && <p id="input-container-error38" className="okta-form-input-error o-form-input-error o-form-explain errormessae-width-email" role="alert">
                    <span className="icon icon-16 error-16-small" role="img" aria-label="Error"></span>{alertMessage}</p>}
                </div>
              </div>
              <button id="submitEmail" data-se="email-button" className="button button-primary button-wide email-button mt-4_2" href="#" >Confirm email</button>
            </div>
          </div>
        </form>
        <div className="auth-footer" onClick={handleBack}><a href="/signin" className="link help js-back" data-se="back-link">Back to sign in page</a></div>
        {/* skip enrollment */}
        {userInfo && userInfo.data.UIBypassCounter < 2 && <div id="skipAccountRecovery" name="skipAccountRecovery" className="auth-footer pt-0" ><a href="#" id="skipAccountRecovery" className="link help js-back " data-se="back-link" onClick={handleBackSkipEnrollment}>Set up recovery methods later</a></div>}
        {userInfo && userInfo.data.UIBypassCounter == 0 &&
          <div className="skip-times">
            <p className="mt-0 mb-0 pb-0"><span>Attempts remaining to postpone set up: 2</span> </p>
            <p className="mt-0 mb-0">If you are unable to start this process at this time, you can postpone set up two times.</p>
            <p className="mt-3 mb-0">You can start the set-up process at any time via <span>"Sign in & security" settings in your personal profile.</span></p>
          </div>}
        {userInfo && userInfo.data.UIBypassCounter == 1
          && <div className="skip-times"><p className="mt-0 mb-0 pb-0"><span>Attempts remaining to postpone set up: 1</span></p><p className="mt-0 mb-0">If you are unable to start this process at this time, you can postpone set up one time.</p> <p className="mt-3 mb-0">You can start the set-up process at any time via <span>"Sign in & security" settings in your personal profile.</span></p>
          </div>}
        {userInfo && userInfo.data.UIBypassCounter == 2 && <div className="skip-times">
          <p className="mt-0 mb-0 pb-0"><span>Attempts remaining to postpone set up: 0</span>
          </p>
          <p className="mt-0 mb-0">Set up can no longer be postponed.</p>

        </div>}


      </div>
    )
  }
  // phone enrollment
  if (props === 'phone-enrollment') {
    return (<>
      {releaseFeatureFlag === null ? getSpiningLoader() :
        <div style={{ classes }} className='forgot-username'>
        <div data-se="o-form-content" className="o-form-content o-form-theme clearfix">
          <h2 data-se="o-form-head" className="okta-form-title o-form-head">Account recovery enrollment</h2>
          {errorMessage && <div className="okta-form-infobox-error infobox infobox-error" role="alert">
            <span className="icon error-16"></span><p>{errorMessage}</p>
          </div>}
          <div>
            <h1 className="heading-inner-page d-block">Account recovery set up</h1>
            <p className="sub-heading-inner-page p-0">Choose your secondary authentication method to recover your account.</p>
          </div>
          <div className="o-form-fieldset-container account-recovery-setup-head-body" data-se="o-form-fieldset-container">
            <div className="mt-4 pt-2">
              <h6 id="forgot-password-title-txt-small" className="mb-0 mt-0">Text message (SMS) recovery</h6>
              <p className="okta-form-subtitle o-form-explain m-0" data-se="o-form-explain">Recover your account using a temporary passcode sent to your phone via text.</p>
              <button onClick={() => { history.push({ pathname: "/setup-phone-enrollment", search: "sms", state: { type: 'sms' } }) }} data-se="email-button" className="button button-primary button-wide email-button mt-4" href="#">{releaseFeatureFlag ? RELEASE_1_1.TEXT_MESSAGE_BTN : RELEASE_1_0.TEXT_MESSAGE_BTN}</button>

            </div>

            <div className="phone-enrollment mt-4 pt-2">
              <h6 id="forgot-password-title-txt-small-call" className="mb-0 mt-0">Voice call recovery</h6>
              <p className="okta-form-subtitle o-form-explain m-0" data-se="o-form-explain">Recover your account by following voice instructions via a phone call.</p>
              <button onClick={() => { history.push({ pathname: "/setup-phone-enrollment", search: "call", state: { type: 'call' } }) }} data-se="email-button" className="button button-primary button-wide email-button mt-4" href="#" >{releaseFeatureFlag ? RELEASE_1_1.VOICE_CALL_BTN : RELEASE_1_0.VOICE_CALL_BTN}</button>

            </div>
          </div>
        </div>
        <div className="auth-footer" onClick={handleBack}><a href="/signin" className="link help js-back" data-se="back-link">Back to sign in page</a></div>
        {userInfo && userInfo.data.UIBypassCounter < 2 && <div className="auth-footer" ><a href="#" className="link help js-back" data-se="back-link" onClick={handleBackSkipEnrollment}>Set up recovery methods later</a></div>}

        {userInfo && userInfo.data.UIBypassCounter == 0 &&
          <div className="skip-times">
            <p className="mt-0 mb-0 pb-0"><span>Attempts remaining to postpone set up: 2</span> </p>
            <p className="mt-0 mb-0">If you are unable to start this process at this time, you can postpone set up two times.</p>
            <p className="mt-3 mb-0">You can start the set-up process at any time via <span>"Sign in & security" settings in your personal profile.</span></p>
          </div>}
        {userInfo && userInfo.data.UIBypassCounter == 1
          && <div className="skip-times"><p className="mt-0 mb-0 pb-0"><span>Attempts remaining to postpone set up: 1</span></p><p className="mt-0 mb-0">If you are unable to start this process at this time, you can postpone set up one time.</p> <p className="mt-3 mb-0">You can start the set-up process at any time via <span>"Sign in & security" settings in your personal profile.</span></p>
          </div>}
        {userInfo && userInfo.data.UIBypassCounter == 2 && <div className="skip-times">
          <p className="mt-0 mb-0 pb-0"><span>Attempts remaining to postpone set up: 0</span>
          </p>
          <p className="mt-0 mb-0">Set up can no longer be postponed.</p>
        </div>}

      </div>}
      </>
    );
  }
  // setup phone enrollment
  if (location && location.pathname.includes('setup-phone-enrollment')) {
    if (document.getElementById("footer-container-div") !== null) {
      document.getElementById('footer-container-div').className = 'footer-login footer-container-relative';
    }
    const enrollmentType = location.search.replace("?", "");
    let accountEnrollmentTypeHeader = ''
    let accountEnrollmentTypeInfo = ''
    let accountEnrollmentTypeInfoTwo = ''
    let accountEnrollmentTypeButton = ''
    let accountEnrollmentTypeInputLabel=''
    let accountEnrollmentTypeSubHeading=''
    let accountEnrollmentTypeSubButton=''
    let customParaTip = 'The number above is associated with your account, please update if number is incorrect.'
    if (enrollmentType === 'sms') {
      accountEnrollmentTypeHeader = 'Text message recovery set up'
      accountEnrollmentTypeInfo = 'To complete recovery set up, your phone number must be confirmed.'
      accountEnrollmentTypeInfoTwo ="Please enter your mobile phone number to receive a verification passcode via text."
      accountEnrollmentTypeButton = "Send passcode"
      accountEnrollmentTypeInputLabel = "Mobile phone number"
      accountEnrollmentTypeSubHeading = "Enter the one-time verification passcode that was sent to your device to confirm your phone number."
      accountEnrollmentTypeSubButton="Send a new passcode"
    } else if (enrollmentType === 'call') {
      accountEnrollmentTypeHeader = 'Voice call recovery set up'
      accountEnrollmentTypeInfo = 'To complete recovery set up, your phone number must be confirmed. Please enter your phone number to receive a verification passcode via voice call.'
      customParaTip = 'Tip: you can use commas before and after the extension to match the timing of your phone tree prompts.'
      accountEnrollmentTypeButton = "Call with passcode"
      accountEnrollmentTypeInputLabel = "Phone number"
      accountEnrollmentTypeSubHeading="Enter the one-time verification code you received via voice call."
      accountEnrollmentTypeSubButton="Call with a new passcode"
    }
    var country = "us"
    let phoneNumber = ""
    let extensionNumber = ""
    if (userInfo && userInfo.data) {
      if (userInfo.data.hasOwnProperty('mobile') || userInfo.data.hasOwnProperty('phone_number')) {
        enrollmentType === 'call' ? phoneNumber = userInfo.data.phone_number : phoneNumber = userInfo.data.mobile
        enrollmentType === 'sms' ? phoneNumber = userInfo.data.mobile : phoneNumber = userInfo.data.phone_number
        if (phoneNumber && phoneNumber.length > 10 && phoneNumber.startsWith("91")) {
          country = "in"
        }
        if (phoneNumber && phoneNumber.length > 10 && phoneNumber.startsWith("63")) {
          country = "ph"
        }
      }
    }
    if (phoneNumber) {
      const splitPhoneNumber = phoneNumber.split(new RegExp('x', 'i'))
      if (splitPhoneNumber.length > 1) extensionNumber = splitPhoneNumber[1]
      phoneNumber = (splitPhoneNumber[0].replace(/[^\d\+]/g, "").slice(-10))
    }

    const getInputError = () => {
      const phoneNumberElement = document.getElementsByName("phoneNumber");
      if (phoneNumberElement.length > 0 && phoneNumberElement[0].value.replace(/[^\d\+]/g, "").length === 10) {
        setPhoneNumberAlertMessage("");
      }
    }
    return (
      <div style={{ classes }} data-testid="setup-phone-enrollment" className='forgot-username'>
        <form onSubmit={verifyAndActivateFactorAPICall(enrollmentType)} data-se="o-form" slot="content" id="form_username" className="o-form forgot-username o-form-edit-mode">
          <div data-se="o-form-content" className="o-form-content o-form-theme clearfix">
            <h2 data-se="o-form-head" className="okta-form-title o-form-head">Text message authentication</h2>
            {errorMessage && <div className="okta-form-infobox-error infobox infobox-error" role="alert">
              <span className="icon error-16"></span><p>{errorMessage}</p>
            </div>}
            <div>
              <h1 className="heading-inner-page d-block">{accountEnrollmentTypeHeader}</h1>
              <div id="beforResendPage">
              <p id="custom-title-info" className={`sub-heading-inner-page ${enrollmentType === 'call' ? 'mb-0' : 'mb-4'}`}>{accountEnrollmentTypeInfo}</p>
              <p className="sub-heading-inner-page m-0 p-0">{accountEnrollmentTypeInfoTwo}</p>
              </div>
              <div id="afteResendPage" className="hide">
                <p className="sub-heading-inner-page mb-2 p-0">{accountEnrollmentTypeSubHeading}</p>
              </div>
              <hr id="custom-title-hr" />
            </div>
            <div className="o-form-fieldset-container" data-se="o-form-fieldset-container">
              <div data-se="o-form-fieldset" id="labelBeforeBox" className="o-form-fieldset o-form-label-top">
                <div data-se="o-form-label" className="okta-form-label form-label-without-margin-top hide">
                  <label name="country" htmlFor="countrynames" id="forgot-username-country-two">Country</label>
                </div>
                <div data-se="o-form-input-container" className={`${phoneNumberAlertMessage ? 'error-border-phone' : ''} o-form-input o-form-has-errors`}>
                  <div className="inputWidth">
                   <label name="country" htmlFor="countrynames" id="forgot-username-country" className="opacity-0 h-0 position-absolute ">Country</label>
                    <PhoneInput
                      type='tel' className='operations-supplierCapacity country-select-operation'
                      name="countryName" aria-label="countrynames"
                      id="countrynames"
                      masks={{
                        pr: "(...) ...-...."
                      }}
                      aria-invalid="true" country={country} preserveOrder={"onlyCountries"} onlyCountries={envConfig.COUNTRIES_ALLOWED}
                      inputProps={{
                        name: 'countryName',
                        id: "countrynames",
                        'aria-labelledby':"countrynames",
                        'aria-describedby':"countrynames"
                      }}
                    >
                    </PhoneInput>
                  </div>
                  {alertMessage && <p id="input-container-error38" className="okta-form-input-error o-form-input-error o-form-explain" role="alert">
                    <span className="icon icon-16 error-16-small" role="img" aria-label="Error"></span>{alertMessage}</p>}
                </div>
              </div>
              <div data-se="o-form-fieldset" className="o-form-fieldset o-form-label-top">
                <div data-se="o-form-input-container" id="inputBeforeBox" className="o-form-input o-form-has-errors">
                  <div className={ `${phoneNumberAlertMessage ? 'error-border-phone' : ''}`}>
                  <div data-se="o-form-label" className="okta-form-label form-label-without-margin-top">
                    <label id="forgot-username" htmlFor="dialCode">{accountEnrollmentTypeInputLabel}</label>
                  </div>
                  <div className="phone-input-selct-country-both">
                  <span data-se="o-form-input-username" className="o-form-input-name-username o-form-control okta-form-input-field-customUi input-fix o-form-has-errors">
                    <input placeholder="" id="dialCode"
                      type='text' className='operations-supplierCapacity'
                      name="dialCode" aria-label="dialCode"
                      aria-describedby="dialCode"
                      aria-invalid="true" disabled
                    />
                  </span>
                  <span id="inputPhoneNumber" data-se="o-form-input-username" className="o-form-input-name-username o-form-control okta-form-input-field-customUi-phone input-fix o-form-has-errors">
                    <Phone
                      type='tel' className='operations-supplierCapacity'
                      name="phoneNumber" aria-label="phone-numbers-country" placeholder="(XXX) XXX-XXXX"
                      aria-describedby="input-container-error38"
                      aria-invalid="true" value={phoneNumber} onChange={getInputError}>
                    </Phone>
                  </span>
                  </div>
                  {phoneNumberAlertMessage && <p id="input-container-error38" className="okta-form-input-error o-form-input-error o-form-explain top-2" role="alert">
                    <span className="icon icon-16 error-16-small" role="img" aria-label="Error"></span>{phoneNumberAlertMessage}</p>}
                </div>
                </div>
                {enrollmentType === 'call' &&
                <>
                  <div id="extention-after-resend-code" data-se="o-form-fieldset" className="o-form-fieldset o-form-label-top">
                    <div data-se="o-form-label" className="okta-form-label form-label-without-margin-top">
                      <label id="forgot-username-call">Ext (optional)</label>
                    </div>
                    <div data-se="o-form-input-container" className="o-form-input o-form-has-errors">
                      <span data-se="o-form-input-username" className="o-form-input-name-username o-form-control okta-form-input-field input-fix o-form-has-errors">
                        <input type="text" placeholder='XXXXXX' id="extensionNumber" name="extensionNumber" onBlur={extensionNumberValidator} onFocus={extensionNumberValidator} defaultValue={extensionNumber} />
                      </span>
                      {extensionAlertMessage && <p id="input-container-error38" className="okta-form-input-error o-form-input-error o-form-explain" role="alert">
                        <span className="icon icon-16 error-16-small" role="img" aria-label="Error"></span>{extensionAlertMessage}</p>}
                    </div>
                  </div>
                  <div className="custom-gray-text custom-gray-text-custom pt-1 pb-0" id="passcodehideshownew">
                    <p className="pb-0"><span>Tip:</span> Use commas before and after the extension to match the timing of your phone tree prompts. <a href="https://www.cardinalhealth.com/en/cmp/un/rp/login-help-resources.html" className="link help js-back" data-se="back-link" target="_blank">Learn more</a></p>
                  </div>
                  </>
                  }
                {/* <div id="passcodehideshow" className="custom-gray-text">
                  {!extensionAlertMessage && <p id="custom-para-p" className="mt-2">{customParaTip}{(enrollmentType === 'call') && <a href="https://www.cardinalhealth.com/en/cmp/un/rp/login-help-resources.html" className="link help js-back" data-se="back-link" target="_blank">Learn more</a>}</p>}
                  <hr id="custom-title-hr" />
                </div> */}
                <div data-se="o-form-fieldset" className="o-form-fieldset o-form-label-top passcode-input-box">
                  <div data-se="o-form-input-container" className="o-form-input o-form-has-errors">
                    <span data-se="o-form-input-username" id="passcodeInputbox" className="o-form-input-name-username o-form-control okta-form-input-field-custompasscode input-fix o-form-has-errors">
                      <div data-se="o-form-label" className="okta-form-label o-form-label">
                        <label id="forgot-username-passcode">Passcode</label>
                      </div>
                      <input type="number" pattern="[0-9]" placeholder="Enter passcode" name="passcode" id="inputPasscode" aria-label="" className='o-form-input-name-username o-form-control okta-form-input-field input-fix o-form-has-errors' aria-describedby={passCodeAlertMessage?"input-container-error38":""} aria-invalid={passCodeAlertMessage ? true : false} onKeyDown={clearPasscodeAlert} />
                      {passCodeAlertMessage && <p id="input-container-error38" className="okta-form-input-error o-form-input-error o-form-explain" role="alert">
                        <span className="icon icon-16 error-16-small" role="img" aria-label="Error"></span>{passCodeAlertMessage}</p>}
                      <div data-se="o-form-label" className="o-form-label"><p id="dummy-username1" className="margin-top-12"></p></div>
                      <hr id="custom-title-hr" />
                      <div>
                        <button data-se="email-button" className="button button-primary button-wide email-button">Confirm passcode</button>
                        <button data-se="email-button" id='resendPasscode' onClick={resendPassCode(enrollmentType)} className="button button-secondary button-wide email-button link-button-disabled" >{accountEnrollmentTypeSubButton}</button>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
              <button data-se="email-button" name='passcodebutton' id='passcodebutton' onClick={sendPasscodeAPICall(enrollmentType)} className={`button button-primary button-wide email-button ${enrollmentType === 'call' ? "mt-4" : "mt-4_3"}`}>{accountEnrollmentTypeButton}</button>
            </div>
          </div>
        </form>
        <div className="auth-footer"><a href="/phone-enrollment" className="link help js-back" data-se="back-link">Choose a different recovery method</a></div>
      </div>
    )

  }
  // enrollment success 
  if (location && location.pathname.includes('enrolled')) {
    const enrolledFactorType = location.search.replace("?", "")
    let enrolledFactor = ''
    if (enrolledFactorType === 'sms') enrolledFactor = 'text message';
    if (enrolledFactorType === 'call') enrolledFactor = 'voice call';
    if (enrolledFactorType === 'email') enrolledFactor = 'email address';
    return (
      <div style={{ classes }} className='forgot-username'>
        <div>
          <h1 className="heading-inner-page d-block">Account recovery set up complete</h1>
          <p className="sub-heading-inner-page p-0">Your phone number has been confirmed. You can now recover your account via chosen method.</p>
        </div>
        <div className="o-form-fieldset-container mt-4" data-se="o-form-fieldset-container">
          <button data-se="email-button" onClick={orderExpress} className="button button-primary button-wide email-button" href="#" >Go to homepage</button>
        </div>
        <div className="mt-4">
          <p className="sub-heading-inner-page recovery-text">{ alertMsg ? "Your account recovery information can be updated in personal settings through the Update account recovery details option.": "Your account recovery information can be updated in personal settings"}</p>
        </div>
      </div>
    )
  }

  //account setup complete page

  if (location && location.pathname.includes('account-setup-complete')) {
    return (
      <div style={{ classes }} className='forgot-username'>
        <div>
          <h1 className="heading-inner-page d-block">Account set up complete</h1>
          <p className="sub-heading-inner-page p-0">You can now sign in using your updated {releaseFeatureFlag ? RELEASE_1_1.USER_ID : RELEASE_1_0.USERNAME} and password</p>
        </div>
        <div className="o-form-fieldset-container mt-4" data-se="o-form-fieldset-container">
        <button data-se="email-button" onClick={orderExpress} className="button button-primary button-wide email-button" href="#" >Go to homepage</button>
        </div>
        <div className="mt-4">
          <p className="sub-heading-inner-page recovery-text">For accounts with Advanced Reporting, please allow 24-48 hours for your reporting modules to sync.</p>
        </div>
      </div>
    )
  }

  //password-reset-complete page
  if (location && location.pathname.includes('password-reset-complete')) {
    return (
      <div style={{ classes }} className='forgot-username'>
        <div>
          <h1 className="heading-inner-page d-block">Password reset complete</h1>
          <p className="sub-heading-inner-page p-0 mb-4_2">You can now sign in using your updated {releaseFeatureFlag ? RELEASE_1_1.USER_ID : RELEASE_1_0.USERNAME} and password</p>
        </div>
        <div className="o-form-fieldset-container mt-4" data-se="o-form-fieldset-container">
          <button data-se="email-button" onClick={orderExpress} className="button button-primary button-wide email-button" href="#" >Go to homepage</button>
        </div>
      </div>
    )
  }

  return null;
}

console.log('1');
console.log('2');
console.log('3');
console.log('4');
console.log('5');
console.log('6');
console.log('7');
console.log('8');
console.log('9');
console.log('0');
console.log('11');

export default EnrollmentForm;