import React, { useEffect } from "react";
import { retrieveUsername, reactivateAccount } from '../resource/APIServices';
import classes from "../../src/styles/signin.scss";
import 'bootstrap/dist/css/bootstrap.css';
import 'react-phone-input-2/lib/style.css'
import { useHistory } from "react-router-dom";
import { RELEASE_1_0,RELEASE_1_1, BRAND_NAME} from "../Utility/constants";

const Form = ({ props, releaseFeatureFlag }) => {
    const [errorMessage, setErrorMessage] = React.useState("");
    const [alertMessage, setAlertMessage] = React.useState("");     
    const history = useHistory();
    var fromUri = "";
    
    const setDocumentTitle = (title) => {
        const titleElement = document.querySelector("title");
        if (titleElement) {
            titleElement.innerHTML = title;
        }
    };
    
    const handleFromUri = (sourceUrl) => {
        let fromUri = "";
        if (sourceUrl.includes("fromURI")) {
            fromUri = sourceUrl.substring(sourceUrl.indexOf("fromURI=") + 8);
        }
        if (fromUri.includes("jarvis") || fromUri.includes("vantus")) {
            sessionStorage.setItem("whereItCameFrom", "vantus");
            setDocumentTitle("Vantus\u2122 HQ");
        }
    };
    
    const updateTitleBasedOnSession = () => {
        const whereItCameFrom = sessionStorage.getItem("whereItCameFrom");
        const titles = {
            "vantus": "Vantus\u2122 HQ",
            "orderexpress": "Order Express"
        };
        if (titles[whereItCameFrom]) {
            setDocumentTitle(titles[whereItCameFrom]);
        }
    };
    
    useEffect(() => {
        const sourceUrl = decodeURIComponent(window.location.href);
        handleFromUri(sourceUrl);
    }, [props]);
    
    useEffect(() => {
        updateTitleBasedOnSession();
    }, [props]);

    const handleSubmitReactivate = async (e) => {
        e.preventDefault();
        if (document.getElementById('reactivate-account-username')?.value === '') {
            setAlertMessage(`Please enter ${releaseFeatureFlag ? RELEASE_1_1.USER_ID : RELEASE_1_0.USERNAME}.`);
        } else {
            setErrorMessage("");
            setAlertMessage("");
            const isSuccess = await reactivateAccount(document.getElementById('reactivate-account-username')?.value,releaseFeatureFlag);
            if(isSuccess){
                history.push('/reactivate/emailed');
            } else {
                history.push('/generic-error');
            }
        }
    }

    const handleSubmitReactivateOnChange = () => {
        if (document.getElementById('reactivate-account-username')?.value === '') {
            setAlertMessage(`Please enter ${releaseFeatureFlag ? RELEASE_1_1.USER_ID : RELEASE_1_0.USERNAME}.`);
        } else {
            setErrorMessage("");
            setAlertMessage("");
        }
    }

    const handleSubmitUsername = async (e) => {
        e.preventDefault();
        if (document.getElementById('forgot-account-username').value === '') {
            setAlertMessage('Please enter email address.');
        } else {
            setErrorMessage("");
            setAlertMessage("");
            const isSuccess = await retrieveUsername(document.getElementById('forgot-account-username').value,releaseFeatureFlag);
            if(isSuccess) {
                history.push({ pathname: '/username/emailed', search: document.getElementById('forgot-account-username').value , state: { detail: document.getElementById('forgot-account-username').value } });
            } else {
                history.push('/generic-error')
            }
        }
    }

    const handleSubmitUsernameOnChange = (e) => {
        if (document.getElementById('forgot-account-username').value === '') {
            setAlertMessage('Please enter email address.');
            
        } else {
            emailValidator(e);
            setErrorMessage("");
            setAlertMessage("");
        }
    }

    const handleBack = (e) => {
        e.preventDefault();
        if(sessionStorage.getItem("whereItCameFrom") === "vantus") history.push(BRAND_NAME.FROM_URI);
        else  history.push('/signin');
    }

    if (props.path === 'username_emailed') {
        return (
            <div style={{ classes }} data-testid="username_emailed" className="forgot-username">
                <form onSubmit={handleBack} data-se="pwd-reset-email-sent" slot="content" id="form84" className="o-form o-form-edit-mode">
                    <div data-se="o-form-content" className="o-form-content o-form-theme clearfix">
                        <h1 id="custom-title-txt">{releaseFeatureFlag ? RELEASE_1_1.USER_ID_LABEL : RELEASE_1_0.USERNAME_LABEL} recovery email sent</h1>
                        <p className="pb-3">Your {releaseFeatureFlag ? RELEASE_1_1.USER_ID : RELEASE_1_0.USERNAME} was sent to the email address associated with your account.</p>
                        <p className="p-0">If you do not receive an email, verify that you entered your email address correctly or try another recovery method.</p>
                    </div>
                    <div className="o-form-fieldset-container" data-se="o-form-fieldset-container">
                        <button data-se="back-button" className="button button-primary button-wide mt-5" href="#">Back to sign in</button>
                    </div>
                </form>
            </div>
        );
    }
    if (props === 'reactivate_emailed') {
        return (
            <div style={{ classes }} data-testid="reactivate_emailed" className="forgot-username">
                    <div data-se="o-form-content" className="o-form-content o-form-theme clearfix">
                        <br></br>
                        <h1 id="custom-title-txt">Activation email sent</h1>
                        <p className="mb-4_1 p-0">An Activation Link has been sent to you.</p>
                        <p className="mb-4_1 p-0">If your {releaseFeatureFlag ? RELEASE_1_1.USER_ID : RELEASE_1_0.USERNAME} matches our records, you should receive an email within a few minutes.</p>
                        <p className="mb-4 p-0">For further assistance, contact your sales representative.</p>
                        <hr id="custom-title-hr" />
                    </div>
                        <div className="o-form-fieldset-container" data-se="o-form-fieldset-container">
                            <br></br>
                            <button data-se="back-button" onClick={handleBack} className="button button-primary button-wide" href="#">Sign in</button>
                        </div>
            </div>
        );
    }


    if (props === 'recovery_emailed') {
        return (
            <div style={{ classes }} data-testid="recovery_emailed" className='forgot-username'>
                <div>
                    <h1 id="custom-title-txt">Password reset email sent</h1>
                    <p className="pb-3">An account recovery link was sent to the email address associated with your account. </p>
                    <p className="pb-2">If you do not receive an email, verify that you entered your {releaseFeatureFlag ? RELEASE_1_1.USER_ID : RELEASE_1_0.USERNAME} correctly or try another recovery method.</p>
                    <div>
                    <div className="auth-footer p-0 auth-footer-custom"><a href="/signin/forgot-password" className="link help js-back" data-se="back-link">Choose a different recovery method</a></div>
                    <div className="auth-footer p-0 auth-footer-custom"><a href="/forgot-username" className="link help js-back" data-se="back-link">Recover {releaseFeatureFlag ? RELEASE_1_1.USER_ID : RELEASE_1_0.USERNAME}</a></div>
                    <div className="auth-footer p-0 auth-footer-custom" id="handlebackbutton" onClick={handleBack}><a href="/signin" className="link help js-back" data-se="back-link">Back to sign in</a></div>
                    </div>
                </div>
                {/* <div className="o-form-fieldset-container" data-se="o-form-fieldset-container">
                    <button data-se="email-button" onClick={handleBack} className="button button-primary button-wide email-button mt-4_2" href="#" >Sign in</button>
                </div> */}
            </div>
        );
    }

    
    if (props === 'unlock_emailed') {
        return (
            <div style={{ classes }} data-testid="unlock_emailed" className='forgot-username'>
                <div>
                    <h1 id="custom-title-txt">Account unlock email sent</h1>
                    <p className="pb-3">An account unlock link was sent to the email address associated with your account.</p>
                    <p className="pb-2">If you do not receive an email, verify that you entered your  {releaseFeatureFlag ? RELEASE_1_1.USER_ID : RELEASE_1_0.USERNAME} correctly or try another recovery method.</p>
                    <div>
                        <div className="auth-footer p-0 auth-footer-custom"><a href="/signin/unlock" className="link help js-back" data-se="back-link">Choose a different recovery method</a></div>
                        <div className="auth-footer p-0 auth-footer-custom"><a href="/forgot-username" className="link help js-back" data-se="back-link">Recover  {releaseFeatureFlag ? RELEASE_1_1.USER_ID : RELEASE_1_0.USERNAME}</a></div>
                        <div className="auth-footer p-0 auth-footer-custom" id="handlebackbutton" onClick={handleBack}><a href="/signin" className="link help js-back" data-se="back-link">Back to sign in</a></div>
                    </div>
                </div>
            </div>
        );
    }

    if (props === 'reactivate') {
       return (
            <div style={{ classes }} data-testid="reactivate" className='reactivate-account'>
                    <div data-se="o-form-content" className="o-form-content o-form-theme clearfix">
                        <h2 data-se="o-form-head" className="okta-form-title o-form-head">Reactivate Account</h2>
                        <div>
                            <h1 id="custom-title-txt">Request a new activation link</h1>
                            <p id="custom-title-info">Enter the {releaseFeatureFlag ? RELEASE_1_1.USER_ID : RELEASE_1_0.USERNAME} associated with your account.</p>
                        </div>
                        <div className="o-form-fieldset-container" data-se="o-form-fieldset-container">
                            <div data-se="o-form-fieldset" className="o-form-fieldset o-form-label-top">
                                <div data-se="o-form-label" className="okta-form-label o-form-label">
                                    <label id="reactivate-username">{releaseFeatureFlag ? RELEASE_1_1.USER_ID_LABEL : RELEASE_1_0.USERNAME_LABEL}</label>
                                </div>
                                <div data-se="o-form-input-container" className="o-form-input o-form-has-errors">
                                    <span data-se="o-form-input-username" className="o-form-input-name-username o-form-control okta-form-input-field input-fix o-form-has-errors">
                                        <input type="text" placeholder={`Enter ${releaseFeatureFlag ? RELEASE_1_1.USER_ID : RELEASE_1_0.USERNAME}`} name="username" onChange={handleSubmitReactivateOnChange} id="reactivate-account-username" aria-label="" autoComplete="username" aria-describedby="input-container-error38" aria-invalid={alertMessage ? true : false} />
                                    </span>
                                    {alertMessage && <p id="input-container-error38" className="okta-form-input-error o-form-input-error o-form-explain" role="alert">
                                        <span className="icon icon-16 error-16-small" role="img" aria-label="Error"></span>{alertMessage}</p>}
                                </div>
                            </div>
                            <button data-se="email-button" onClick={handleSubmitReactivate} className="button button-primary button-wide email-button mt-5 btn-top-forgot-username" href="#">Submit</button>
                        </div>
                    </div>
                <div className="auth-footer" onClick={handleBack}><a href="/signin" className="link help js-back" data-se="back-link">Back to sign in</a></div>
            </div>
        );
    }

    const emailValidator= (e) => {
        let enteredEmailAddr = "";
        if (e && e.target && e.target.value) enteredEmailAddr = e.target.value;
        var emailValidatorRegex = /\S+@\S+\.\S+/;
        let submitUsernameBtn =  document.getElementById('submitUsername');
        if (enteredEmailAddr && !emailValidatorRegex.test(enteredEmailAddr)) {
            submitUsernameBtn.disabled = true;
           // submitUsernameBtn.className ='button button-primary button-wide email-button link-button-disabled'
            setAlertMessage("Please enter a valid email address.");
        } else {
          submitUsernameBtn.disabled = false;
          //submitUsernameBtn.className ='button button-primary button-wide email-button link-button'
          setAlertMessage(undefined);
        }
    }


    if (props.path === 'username') {
        return (
            <div style={{ classes }} data-testid="username" className='forgot-username'>
                    <div data-se="o-form-content" className="o-form-content o-form-theme clearfix">
                        <div>
                            <h1 id="forgot-password-title-txt" className="heading-inner-page">Recover {releaseFeatureFlag ? RELEASE_1_1.USER_ID : RELEASE_1_0.USERNAME}</h1>
                            <p id="forgot-password-title-info" className="sub-heading-inner-page">Please enter the email address associated with your account.</p>
                        </div>
                        <div className="o-form-fieldset-container" data-se="o-form-fieldset-container">
                            <div data-se="o-form-fieldset" className="o-form-fieldset o-form-label-top">
                                <div data-se="o-form-label" className="okta-form-label form-label-without-margin-top">
                                    <label id="forgot-username">Email address</label>
                                </div>
                                <div data-se="o-form-input-container" className="o-form-input o-form-has-errors">
                                    <span data-se="o-form-input-username" className="o-form-input-name-username o-form-control okta-form-input-field input-fix o-form-has-errors">
                                        <input type="text" placeholder="Enter email address" name="username" id="forgot-account-username" onChange={handleSubmitUsernameOnChange} onBlur={emailValidator} onFocus={emailValidator} aria-invalid={alertMessage ? true : false}/>
                                    </span>
                                    {alertMessage && <p id="input-container-error38" className="okta-form-input-error o-form-input-error o-form-explain" role="alert">
                                        <span className="icon icon-16 error-16-small" role="img" aria-label="Error"></span>{alertMessage}</p>}
                                </div>
                            </div>
                            <button id="submitUsername" data-se="email-button" onClick={handleSubmitUsername} className="button button-primary button-wide email-button mt-5 btn-top-forgot-username" href="#" >Recover {releaseFeatureFlag ? RELEASE_1_1.USER_ID : RELEASE_1_0.USERNAME}</button>
                        </div>
                    </div>
                <div className="auth-footer" onClick={handleBack}><a href="/signin" className="d-inline-block link help js-back mt-2" data-se="back-link">Back to sign in</a></div>
            </div>
        );
    }

    if (props === 'generic_error') {
        return (
            <div style={{ classes }} >
                    <div data-se="o-form-content" className="o-form-content o-form-theme clearfix">
                        <h1 id="custom-title-txt">Sorry, an error occurred.</h1>
                        <p >We are working to fix the issue. Please try logging in again. <a className="link help js-back" href='/signout'>Sign in now</a>.</p>
                        <p >Or, if the problem persists, call 1-800-326-6457.</p>
                    </div>
            </div>
        );
    }
    return null;
}


export default Form;