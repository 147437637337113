export const USERNAME = 'Username';
export const USERID = 'User ID';

export const RELEASE_1_0 = {
    USERNAME_LABEL: 'Username',
    USERNAME: 'username',
    ENTER_USERNAME: 'Enter username',
    SMS_BTN :'Select text message (SMS) recovery',
    TEXT_MESSAGE_BTN :'Select text message recovery',
    EMAIL_BTN :'Select email recovery',
    VOICE_CALL_BTN :'Select voice call recovery',
    FORGOT_USERNAME: 'Forgot username',
}
export const RELEASE_1_1 = {
    USER_ID_LABEL: 'User ID',
    USER_ID: 'user ID',
    ENTER_USER_ID: 'Enter user ID',
    SMS_BTN :'Text message (SMS)',
    TEXT_MESSAGE_BTN :'Text message',
    EMAIL_BTN :'Email',
    VOICE_CALL_BTN :'Voice call',
    FORGOT_USER_ID: 'Forgot user ID',
}

export const BRAND_NAME = {
    FROM_URI : '/signin?fromURI=https://vantusdev.cardinalhealth.com/home',
    
}
